export const templeContentsList = [
	{
		number_of_images: 2,
		image_name: '神社全景',
		text: `\
			園生の森神社（奈良紅工房）

			『天神さんをお祀りするつもりで売れても売れなくても梅を焼け』 中西家に代々伝わる烏梅製造。 
			紅花染めの工房には約七百年前に月ヶ瀬に烏梅製造を伝えてくれた園生姫と
			月ヶ瀬の尾山天神神社の天神さん、菅原道真公をお祀りし、園生姫がかつて暮らしたこの地に
			園生の森神社を建立します。
			日本建築の在来工法。全体構想は大神神社のなでうさぎを参考にして、随所に職人技と神明造りを
			取り入れ、洗練された日本古来の技と意匠で造りました。`,
	},
	{
		number_of_images: 1,
		image_name: '懸魚',
		text: `\
			懸魚

			屋根に水（魚）を架ける火災防止のおまじない。
			西暦1331年の元弘の乱の折、後醍醐天皇が京都から吉野に逃れる際に女官の園生姫が月ヶ瀬で
			力尽きて倒れましたが、村人は手厚く看病しました。快復した園生姫は尾山天神神社に梅の実が
			成っているのを見て、看病してくれたお礼として村人に烏梅の製造方法を教えてくれたと
			伝えられています。
			この懸魚は後醍醐天皇がお祀りされている吉野神宮の懸魚の意匠を参考に製作しました。 
			魔を祓って福を招く日本古来の猪の目。釘隠しは六葉菊座。 
			園生姫はその後生涯月ヶ瀬で暮らしました。`
	},
	{
		number_of_images: 1,
		image_name: '千木と鰹木',
		text: `\
			千木・鰹木

			総氏神の伊勢の神宮を参考に、内宮の女千木、内削ぎ、鰹木は6本。
			屋根は尾山天神神社の意匠を参考にしました。`,
	},
	{
		number_of_images: 1,
		image_name: '庇と木鼻',
		text: `\
			庇と木鼻

			庇は大神神社の祈祷殿の銅板葺き3段を参考にしています。
			木鼻は宮大工オリジナルデザイン`,
	},
	{
		number_of_images: 1,
		image_name: '神額_神幕_紋',
		text: `\
			建具と紋・神額・神幕

			都が平城京から長岡京を経て平安京に移った際、新造された内裏の紫宸殿には
			『左近の梅』と『右近の橘』が植えられました。その後、菅原道真公の進言によって
			遣唐使が廃止された頃から国風文化が見直され、『左近の桜』に変わりました。
			園生の森神社の玄関の紋 には『左近の橘』『右近の梅』を入れました。
			丸に橘は中西家の家紋でもあります。星梅鉢紋は北野天満宮を参考にしています。`,
	},
	{
		number_of_images: 1,
		image_name: '石段',
		text: `\
			石段

			千三百年前に遣隋使・遣唐使によって平城京に伝わった烏梅なので
			石段の寸法は平城京朱雀門の9寸を参考にしました。`,
	},
	{
		number_of_images: 1,
		image_name: '竹の塔',
		text: `\
			竹の塔

			紅花染めをするには水がとても大切です。七百年前に烏梅が伝わった当時の水環境を想像し
			竹の塔に雨水を貯め、ろ過して紅花染めに使用します。`,
	},
	{
		number_of_images: 1,
		image_name: '手水舎',
		text: `\
			手水舎

			竹の塔に貯待った雨水はろ過されこの手水舎に繋がります。
			紅花染めをする際の大切な設備です。`,
	},
	{
		number_of_images: 1,
		image_name: '漆喰壁',
		text: `\
			漆喰壁

			材料は石灰、布海苔、麻の繊維のみ。
			調湿効果があり夏は湿気を吸収し、冬は乾燥を防いでくれます。`,
	},
	{
		number_of_images: 3,
		image_name: '組子建具',
		text: `\
			組子建具

			釘を使わずに木で吉祥文様を組み付ける木工技術。
			麻の葉、胡麻、青海波、ねじり組み、橘、梅、七宝を木で繊細に表現しています。
			檜、杉、黒檀、神代杉を使い分けて色の変化を付けています。`,
	},
	{
		number_of_images: 1,
		image_name: '工房',
		text: `\
			工房`,
	},
	{
		number_of_images: 2,
		image_name: '景色',
		text: `\
			工房からの景色

			東向きに伊賀上野盆地や青山高原を見渡せます。`,
	},
	{
		number_of_images: 1,
		image_name: '施工業者一覧',
		text: `\
			施工業者一覧`,
	},
	{
		number_of_images: 1,
		image_name: 'クラウドファンディング御芳名',
		text: `\
			クラウドファンディング御芳名`,
	},
];