import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { getIndexBase64Images, getProducts } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';
import ProductsIndex from './ProductsIndex';

const PopularItems = () => {
	const [products, setProducts] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			context.setLoading(true);

			var productsData;
			var base64ImagesData;
			try {
				productsData = await getProducts(true, true);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'products', objects: productsData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setProducts(productsData);
			setBase64Images(base64ImagesData);

			context.setLoading(false);
		}
		getData();
	}, []);

	return (
		products.length ? 
		<div className='my-[80px] sm:mb-[160px]'>
			<p className='font-allura text-gold-dark sm:text-[20px] text-center'>Popular Items</p>
			<p className='mb-[40px] sm:mb-[80px] font-shippori text-[24px] sm:text-[40px] text-center'>人気商品</p>
			<ProductsIndex products={products} base64Images={base64Images} />
			<a href='/products' className='mt-[40px] sm:mt-[80px] mx-auto btn-gold'>もっと見る</a>
		</div>
		:''
	);
};

export default PopularItems;