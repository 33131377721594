import { React } from 'react';
import { ArticleBanners, Title } from '../../components';
import { templeContentsList } from '../../data/temple';

const TemplePage = () => {
	return (
		<div>
			<Title englishTitle='Sonoh forest shrine' title='園生の森神社' />
			<div className='w-[90%] sm:w-4/5 mx-auto sm:flex flex-wrap'>
				{ templeContentsList.map((obj) => { return (
					<div className={`${(obj.image_name === '神社全景' || obj.image_name === '懸魚') ? 'w-full sm:flex gap-[20px]' : 'w-full sm:w-1/2 px-[10px]'} py-[40px] sm:py-[80px] border-b-2 border-gold border-dotted`}>
						<div className={`${obj.number_of_images > 1 ? 'fade-images aspect-[4/3]' : ''} w-full mb-[20px]`}>
							{ obj.number_of_images > 1 ? 
								[...Array(obj.number_of_images)].map((_, i) => { return (
									<div
										key={i}
										className='w-full aspect-[4/3]'
										style={{
											'background-image': `url('images/temple/${obj.image_name}/${i + 1}.jpg')`,
											'animation-delay': `${i * 5}s`,
											'animation-duration': `${5 * obj.number_of_images}s`,
										}} />
								)})
								:
								<img src={`images/temple/${obj.image_name}.jpg`} alt={`${obj.image_name}の写真`} className='w-full' />
							}
						</div>
						<p className='w-full whitespace-pre-line leading-loose'>{obj.text}</p>
					</div>
				)})}
			</div>
			<div className='py-[40px] sm:py-[80px] w-[90%] sm:w-4/5 mx-auto'>
				<p className='sm:text-[20px] font-allura text-gold-dark'>Access</p>
				<p className='mb-[40px] text-[20px] sm:text-[32px] font-shippori'>アクセス</p>
				<iframe
					title='access'
					src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1639.8367941136564!2d136.04611151555488!3d34.71341199155879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDQyJzQ4LjMiTiAxMzbCsDAyJzUxLjIiRQ!5e0!3m2!1sja!2sjp!4v1730124080160!5m2!1sja!2sjp"
					className='mt-[40px] h-[475px] w-full aspect-video bg-stone-400 rounded-[12px]'
					allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto py-[40px] pb-[80px] sm:py-[80px]'>
				<ArticleBanners articleList={['ubai', 'narabeni', 'agriculture']} />
			</div>
		</div>
	);
};

export default TemplePage;