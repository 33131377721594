import { React } from 'react';

const ArticleBanner = ({href, src, title}) => {
	return (
		<a href={href} className='relative h-[100px] sm:h-[200px] mx-auto sm:w-[45%] group hover:scale-110 transition duration-1000'>
			<img src={src} alt='記事のバナー' className='h-[100px] sm:h-[200px] w-full object-cover'/>
			<div className='absolute top-0 left-0 pl-[20px] w-1/2 h-[100px] sm:h-[200px] bg-stone-800 flex items-center group-hover:bg-white group-hover:w-2/3'>
				<p className='text-gold-light font-bold group-hover:text-gold-dark'>{title} &gt;</p>
			</div>
		</a>
	);
};

const ArticleBanners = ({articleList}) => {
	return (
		<div className='sm:flex flex-wrap sm:gap-[40px]'>
			{articleList ? articleList.map((article, i) => {
				if (article === 'ubai')
					return <ArticleBanner key={i} href='/ubai' src='images/ubai/banner.jpg' title='烏梅' />
				else if (article === 'narabeni')
					return <ArticleBanner key={i} href='/narabeni' src='images/narabeni/banner.jpg' title='奈良紅' />
				else if (article === 'agriculture')
					return <ArticleBanner key={i} href='/agriculture' src='images/agriculture/banner.jpg' title='農業' />
				else if (article === 'temple')
					return <ArticleBanner key={i} href='/temple' src='images/temple/banner.jpg' title='園生の森神社' />
			}) : ''}
		</div>
	)
};

export default ArticleBanners;