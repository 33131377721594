export const narabeniContentsList = [
	{
		english_title: 'Narabeni',
		title: '奈良紅',
		image_folder: 'narabeni',
		number_of_images: 1,
		content: `\
			江戸時代、日本女性は烏梅（うばい）と紅花（べにばな）で作られた口紅を使って美しさを
			引き立てていました。化学染料の発明により、この技術は次第に失われてしまいました。
			私たちは、失われた日本の赤を現代に再現するため、クラウドファンディングで資金を集め、
			古い文献を基に研究を重ね、江戸時代の色合いを再現することに成功しました。

			奈良紅の特徴

			口紅：江戸時代の成分、烏梅と紅花を使用して作られた口紅です。鮮やかな赤が特徴で、
			　　　かつての日本女性たちが愛した美しさを現代に甦らせています。紅花の赤は
			　　　魔除けの赤として特別な意味を持ち、幸運や安全を願う象徴とされてきました。

			容器：奈良の赤膚焼き職人が焼いた陶器で、乳白色の優しい質感が特徴です。
			　　　容器には奈良絵が描かれており、やわらかな雰囲気で紅花、梅、烏梅、そして
			　　　烏梅の道具を表現しています。

			筆：奈良の職人が手作りで仕上げた奈良筆で、最高級のイタチの毛を使用しています。
			　　滑らかで精緻な塗り心地を提供します。

			箱：奈良の木工職人が美しい吉野杉の木目を生かして作った化粧箱です。
			　　贈り物や大切な日にふさわしい、上品で丁寧なデザインが施されています。

			全て手作りの逸品
			『奈良紅』は、現代の奈良の職人たちが一つ一つ手作りで仕上げています。
			江戸時代の美しさを忠実に再現しつつ、現代の美意識を取り入れた逸品です。

			特別な日に
			失われた日本の赤を再現した『奈良紅』は、特別な日に特別な色で輝きます。
			紅花の赤が持つ魔除けの意味をも込めて、特別な瞬間を華やかに彩ります。
			江戸時代の美しさと現代の職人技が融合した「奈良紅」で、特別な日を
			さらに美しく演出してください。`,
	},
	{
		english_title: 'Safflower and Ubai Dyeing',
		title: '紅花染め',
		image_folder: 'dyeing',
		number_of_images: 6,
		content: `\
		紅花染めは、日本の昔ながらの染色技法で、紅花を用いて美しい紅色を生地に染める方法です。
		この技法は長い歴史を持ち、特に平安時代の書物『延喜式』にも記されています。

		歴史と背景
		エジプトからシルクロードを経て中国へエジプト原産の紅花はシルクロードを通じて、現在の中国四川省で栽培されました。
		この地域は現代でも世界最大の紅花産地として知られています。

		日本への伝来日本には3世紀に伝わり、奈良の纏向遺跡で大量の紅花の花粉が発見されています。
		これにより、古代日本での紅花の重要性が示されています。

		韓紅の色紅花を使って烏梅で染めた赤は、魔除けの色とされ、『韓紅』と呼ばれる
		濃い紅色は、十二単などの高貴な衣装に使われました。高貴な女性だけが着用できる
		禁色とされていました。

		技法と製法
		烏梅と紅花の使用韓紅の色は、烏梅を使って紅花染めを施した結果得られます。この技法は、色素が深く、
		かつ鮮やかな紅色を実現することができます。

		奈良紅工房での紅花染め奈良紅工房では紅花と烏梅を使った昔ながらの紅花染めが行われています。
		『延喜式』に記された製法をそのまま守りながら、絹や麻、綿の生地に染めています。

		文化的な役割と現代での利用
		修二会東大寺で奈良時代から一度も途切れることなく続けられている修二会では、
		十一面観音菩薩に椿の造花がお供えされます。その椿の造花を彩る和紙は紅花と烏梅で
		染められています（京都のよしおか工房）
		このような伝統的な儀式で使用されることで、紅花染めの重要性が示されています。

		現代の十二単十二単に使用される紅の色は、古代からの製法をそのまま受け継いでいます。
		これにより、古き良き伝統が現代にも息づいています。
		紅花染めは、その美しい色合いと歴史的背景から、日本の伝統工芸の中でも特に
		価値のある染色技法です。その技術と美しさは、現在でも大切に受け継がれています。`,
	},
];
