export const shopName = '梅古庵';

export const shopInfoText = `
	────────────────────────
	烏梅製造元　梅古庵
	中西謙介
	住所　〒630-2302　奈良県奈良市月ヶ瀬尾山2263
	電話　0743-92-0560
	Email: [新しいメールアドレス]
	URL: [https://baikoan.com/]
	────────────────────────`;

export const pagesList = [
	{
		pathname: '/products',
		title: '商品一覧',
	},
	{
		pathname: '/ubai',
		title: '烏梅',
	},
	{
		pathname: '/narabeni',
		title: '奈良紅',
	},
	{
		pathname: '/activities',
		title: '体験イベント',
	},
	{
		pathname: '/agriculture',
		title: '農業',
	},
	{
		pathname: '/temple',
		title: '園生の森神社',
	},
	{
		pathname: '/store',
		title: 'お取扱店舗',
	},
	{
		pathname: '/blogs',
		title: 'ブログ',
	},
];

export const adminPagesList = [
	{
		pathname: '/orders',
		title: '注文管理',
	},
	{
		pathname: '/products',
		title: '商品管理',
	},
	{
		pathname: '/shipping-methods',
		title: '送料管理',
	},
	{
		pathname: '/articles',
		title: '特集記事管理',
	},
	{
		pathname: '/blogs',
		title: 'ブログ管理',
	},
	{
		pathname: '/news',
		title: 'お知らせ管理',
	},
	{
		pathname: '/reviews',
		title: 'レビュー管理',
	},
];

export const orderStatusList = [
	{
		name: 'pending-payment',
		title: '支払待ち',
		nextStatus: 'pending-shipping',
	},
	{
		name: 'pending-shipping',
		title: '発送待ち',
		nextStatus: 'shipping',
	},
	{
		name: 'shipping',
		title: '発送済み',
		nextStatus: 'completed',
	},
	{
		name: 'completed',
		title: '完了',
	},
	{
		name: 'canceled',
		title: 'キャンセル済み',
	},
];

export const categoryList = ['梅干し', '梅食品', '烏梅', '干し柿', '漬物', '農産物', '奈良紅', '体験イベント'];

export const areaList = [
	{method_name: 'Hokkaido', name: '北海道', prefectures: ['北海道']},
	{method_name: 'Tohoku', name: '東北', prefectures: ['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県']},
	{method_name: 'Kanto', name: '関東', prefectures: ['茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '山梨県']},
	{method_name: 'Sinetsu', name: '信越', prefectures: ['新潟県', '長野県']},
	{method_name: 'Hokuriku', name: '北陸', prefectures: ['富山県', '石川県', '福井県']},
	{method_name: 'Tokai', name: '東海', prefectures: ['岐阜県', '静岡県', '愛知県', '三重県']},
	{method_name: 'Kinki', name: '近畿', prefectures: ['滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県']},
	{method_name: 'Chugoku', name: '中国', prefectures: ['鳥取県', '島根県', '岡山県', '広島県', '山口県']},
	{method_name: 'Shikoku', name: '四国', prefectures: ['徳島県', '香川県', '愛媛県', '高知県']},
	{method_name: 'Kyusyu', name: '九州', prefectures: ['福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県']},
	{method_name: 'Okinawa', name: '沖縄', prefectures: ['沖縄県']},
];

export const prefectureList = [
	"北海道",
	"青森県",
	"岩手県",
	"宮城県",
	"秋田県",
	"山形県",
	"福島県",
	"茨城県",
	"栃木県",
	"群馬県",
	"埼玉県",
	"千葉県",
	"東京都",
	"神奈川県",
	"新潟県",
	"富山県",
	"石川県",
	"福井県",
	"山梨県",
	"長野県",
	"岐阜県",
	"静岡県",
	"愛知県",
	"三重県",
	"滋賀県",
	"京都府",
	"大阪府",
	"兵庫県",
	"奈良県",
	"和歌山県",
	"鳥取県",
	"島根県",
	"岡山県",
	"広島県",
	"山口県",
	"徳島県",
	"香川県",
	"愛媛県",
	"高知県",
	"福岡県",
	"佐賀県",
	"長崎県",
	"熊本県",
	"大分県",
	"宮崎県",
	"鹿児島県",
	"沖縄県",
];

export const paymentList = [
	{
		name: 'card',
		title: 'カード決済',
	},
	{
		name: 'bank',
		title: '銀行振込（先払い）',
	},
	{
		name: 'cash',
		title: '商品代引',
	},
];
