export const mediaContentsList = [
	{
		date: '1982年',
		title: '奈良県民族博物館映像資料　大和の伝承技術『烏梅』',
	},
	{
		date: '',
		title: 'NHK　ニュースなら610わがまちこの人！月ヶ瀬村',
	},
	{
		date: '',
		title: '関西テレビ　痛快！エブリデイ',
	},
	{
		date: '',
		title: '中京テレビ　ワザあり！にっぽん　天然が作った日本の色～月ヶ瀬梅の里・烏梅作り～',
	},
	{
		date: '2003年',
		title: 'NHK　HiVision　『赤とRougr（ルージュ）』～日本とフランス 色の出会い～',
	},
	{
		date: '2014年',
		title: '産経新聞　なら再発見',
	},
	{
		date: '',
		title: '小学館　和楽',
	},
	{
		date: '',
		title: '東京工芸大学卒業研究　映画『紅』',
	},
	{
		date: '',
		title: 'NHK　ならコレ！',
	},
	{
		date: '',
		title: 'NHK　ぐるかん',
	},
	{
		date: '2017年',
		title: 'NHK　BSプレミアム　失われた色を求めて～植物染め・伝統100色を今の世に～',
	},
	{
		date: '',
		title: 'テレビ東京　和風総本家',
	},
	{
		date: '2018年',
		title: '奈良テレビ　ゆうドキッ',
	},
	{
		date: '',
		title: '朝日放送　一志相伝',
	},
	{
		date: '',
		title: 'テレビ東京　所さんの学校では教えてくれないそこんところ',
	},
	{
		date: '',
		title: 'セブンイレブン財団　会報誌',
	},
	{
		date: '',
		title: 'NHK　BS　COOL　JAPAN　発掘かっこいい日本市場',
	},
	{
		date: '2019年',
		title: '小学館　最後の職人ものがたり　三笠宮彬子女王著',
	},
	{
		date: '',
		title: '3月　NHK　あさいち',
	},
	{
		date: '',
		title: '三重テレビ　伊勢美しい国　中西麻紀',
	},
	{
		date: '',
		title: '月刊なら　月刊誌',
	},
	{
		date: '',
		title: 'テレビ朝日　ナニコレ珍百景',
	},
	{
		date: '',
		title: '読売新聞　伝統のもの作り　梅で体験',
	},
	{
		date: '2019年 8月',
		title: 'TBS　世界ふしぎ発見',
	},
	{
		date: '2020年',
		title: '新典社　大伴旅人　辰巳正明著',
	},
	{
		date: '',
		title: '奈良テレビ　ならフライデー9　笑い飯　哲夫',
	},
	{
		date: '',
		title: 'テレビ朝日　ナニコレ珍百景（再編集）',
	},
	{
		date: '',
		title: '近鉄ケーブルテレビ　ならイチ研究所',
	},
	{
		date: '',
		title: 'チルチンびと　105号',
	},
	{
		date: '2021年',
		title: 'きものサロン　きものサロン',
	},
	{
		date: '',
		title: 'NHK　BSプレミアム　穴場ツアー　うちのエリアの日本一　U字工事',
	},
	{
		date: '',
		title: '読売新聞　黒が生む　紅染め鮮やか',
	},
	{
		date: '',
		title: 'テレビ朝日　ナニコレ珍百景（あの人は今）',
	},
	{
		date: '2021年 1月',
		title: '奈良市民だより　奈良市民だより',
	},
	{
		date: '2021年 4月',
		title: 'テレビ朝日　ナニコレ珍百景（あの人は今）再放送',
	},
	{
		date: '2021年 10月',
		title: '映画	紅花の守人',
	},
	{
		date: '2022年 3月',
		title: 'BS　TBS　奈良ふしぎ旅図鑑',
	},
	{
		date: '',
		title: '奈良テレビ　ゆうドキッ　笑福亭鉄瓶',
	},
	{
		date: '',
		title: 'BS朝日　暦に集う',
	},
	{
		date: '',
		title: '日刊工業新聞社　天然染料と衣服　青木正明著',
	},
	{
		date: '2022年 5月',
		title: '毎日放送　京都知新',
	},
	{
		date: '',
		title: '朝日新聞　烏梅「最後の農家」の挑戦',
	},
	{
		date: '2022年 8月',
		title: 'NHK　えぇトコ',
	},
	{
		date: '2022年 9月',
		title: 'NHK　えぇトコ',
	},
	{
		date: '',
		title: 'NHK奈良　ならナビ',
	},
	{
		date: '',
		title: 'NHKラジオ第1　関西ラジオワイド',
	},
	{
		date: '',
		title: 'NHK大阪　ほっと関西',
	},
	{
		date: '2022年 10月',
		title: '柴田書店　日本の伝統食品',
	},
	{
		date: '',
		title: 'NHK　えぇトコ総集編',
	},
	{
		date: '',
		title: 'NHK　BS1　えぇトコ',
	},
	{
		date: '2022年 11月',
		title: 'NHK　きん5時',
	},
	{
		date: '',
		title: 'NHK　えぇトコスペシャル　関西ふるさとの味100',
	},
	{
		date: '2023年 2月',
		title: '毎日新聞',
	},
	{
		date: '2023年 3月',
		title: '毎日新聞　森林ジャーナリスト',
	},
	{
		date: '',
		title: 'NHK　ならナビ',
	},
	{
		date: '',
		title: 'NHK　ほっと関西サタデー',
	},
	{
		date: '2023年 4月',
		title: 'KBS京都ラジオ　山田啓二のローカルフィット',
	},
	{
		date: '2023年 12月',
		title: '奈良商工会議所HP　SDGs注力企業',
	},
	{
		date: '2024年 3月',
		title: '読売テレビ　遠くへ行きたい',
	},
	{
		date: '',
		title: '朝日放送　newsおかえり　いっとこfufu',
	},
	{
		date: '2024年 4月',
		title: '月ヶ瀬福祉センターだより　地域の宝物『烏梅づくり』を守っていきたい',
	},
	{
		date: '2024年 5月',
		title: 'フェアフィールドbyマリオット　日本古来の伝統文化を嗜みたいときの居場所。',
	},
	{
		date: '2024年 8月',
		title: 'NHKラジオ第1　マイあさだより',
	},
	{
		date: '2024年 12月',
		title: '小学館　日本美のこころ　三笠宮彬子女王著',
	},
];

export const mediaPictureList = [
	'NHKえぇトコ',
	'NHKニュース',
	'NHKニュース_',
	'NHKニュース__',
	'NHKほっと関西',
	'NHK遠くへ行きたい',
	'TBS奈良ふしぎ旅図鑑',
	'ナニコレ珍百景',
	'世界ふしぎ発見',
	'朝日新聞',
	'朝日放送ニュース',
	'奈良テレビ',
	'毎日新聞',
];

export const lectureContentsList = [
	{
		date: '2022年 9月 13日',
		title: '『映画紅花の守り人』　アフタートーク　ポレポレ東中野',
	},
	{
		date: '2022年 11月 12日',
		title: 'グリグリマルシェ　烏梅とは　難波神社',
	},
	{
		date: '2023年 1月 24日',
		title: '月ヶ瀬中学校　烏梅特別講義　月ヶ瀬中学校',
	},
	{
		date: '2024年 1月 28日',
		title: '清水寺　北法相宗仏教文化講座　清水寺円通殿',
	},
	{
		date: '2024年 5月 26日',
		title: 'はぐらぶマルシェ　烏梅について　月ヶ瀬行政センター広場',
	},
	{
		date: '2024年 8月 2日',
		title: '月ヶ瀬公民館　烏梅について　月ヶ瀬公民館',
	},
	{
		date: '2024年 8月 18日',
		title: '奈良のトビラ　烏梅について　平城宮いざない舘',
	},
	{
		date: '2024年 12月 8日',
		title: '大人の寺子屋　余白　烏梅講義と酸梅湯つくりの実習　三井寺町　大人の寺子屋',
	},
	{
		date: '2024年 12月 8日',
		title: '大人の寺子屋　余白　烏梅を取り入れた創作料理　三井寺町　大人の寺子屋',
	},
	{
		date: '2025年 2月4日',
		title: '大人の寺子屋　余白　烏梅を取り入れた創作料理　三井寺町　大人の寺子屋',
	},
];
