export const agricultureContentsList = [
	{
		english_title: 'Plum',
		title: '梅',
		image_folder: 'plum',
		number_of_images: 5,
		content: `\
		30,000平米の広大な土地と緑あふれる豊かな自然の中で多品種の梅を栽培しています。 
		在来種の『鶯宿』、『白加賀』、改良品種の『南高』、『紅映』を中心に育てています。
		『城州白』は烏梅づくりに適した品種として、最盛期の江戸時代に植えられた木が
		今も残っています。 樹齢は二百年を超え、昭和28年の大災害でも倒れることなく、
		肥料も農薬も与えない現在でも、毎年花を咲かせ立派な果実が成ります。
		自分より年上の長寿の梅を頂くありがたさを感じています。 `,
	},
	{
		english_title: 'Persimmon',
		title: '柿',
		image_folder: 'persimmon',
		number_of_images: 4,
		content: `\
		富有柿、鶴の子、禅寺丸、富士柿、愛宕柿、蜂屋柿の複数の品種の柿を育てています。`,
	},
	{
		english_title: 'Rice',
		title: '米',
		image_folder: 'rice',
		number_of_images: 3,
		content: `コシヒカリ、ヒノヒカリを栽培しています。`,
	},
	{
		english_title: 'Fruits and Vegetables',
		title: '野菜・果物',
		image_folder: 'fruits_and_vegetables',
		number_of_images: 6,
		content: `\
		日野菜、赤カブ、じゃが芋、玉葱、にんにく、葱、きゅうり、かぼちゃ、トマト、 枝豆、大豆、スナップエンドウ、里芋、落花生 柿、みかん、桃、すだち、柚子 `,
	},
	{
		english_title: 'Safflower',
		title: '紅花',
		image_folder: 'safflower',
		number_of_images: 4,
		content: `\
		烏梅を使った紅花染めをするために、自社で紅花を栽培しています。
		在来種と改良品種の『最上紅花』`,
	},
];
