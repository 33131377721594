import { React } from 'react';

const ErrorPage = () => {
	return (
		<div className='pt-[160px] h-screen'>
			<p className='mb-2 text-center'>エラーが発生しました</p>
			<p className='text-center underline hover:text-gold-dark'>
				<a href='/'>トップへ戻る</a>
			</p>
		</div>
	);
};

export default ErrorPage;
