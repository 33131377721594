import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { ArticleBanners, ProductsIndex, Title, TopImagesCarousel } from '../../components';
import { agricultureContentsList } from '../../data/agriculture';
import { getIndexBase64Images, getProducts } from '../../functions';
import { LoadingContext } from '../../functions/context/LoadingFunc';

const AgriculturePage = () => {
	const [products, setProducts] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const loading_context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			loading_context.setLoading(true);

			var productsData;
			var base64ImagesData;
			try {
				productsData = await getProducts(false, true);
				productsData = productsData.filter((p) => ['干し柿', '漬物', '農産物'].find((c) => c === p.category));
			} catch (err) {
				showBoundary(err);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'products', objects: productsData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setProducts(productsData);
			setBase64Images(base64ImagesData);

			loading_context.setLoading(false);
		};
		getData();
	}, []);

	return (
		<div>
			<Title title='農業' englishTitle='Agriculture' />
			<TopImagesCarousel image_folder='images/agriculture/top' number_of_images={5} name='agriculture' />
			{ agricultureContentsList.map((obj) => { return (
				<div className='py-[40px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5'>
					<p className='sm:text-[20px] font-allura text-gold-dark'>{obj.english_title}</p>
					<p className='mb-[40px] text-[20px] sm:text-[32px] font-shippori'>{obj.title}</p>
					<div className={`${obj.number_of_images > 1 ? 'fade-images' : ''} sm:w-[640px] h-[200px] sm:h-[400px]`}>
						{ obj.number_of_images > 1 ? 
							[...Array(obj.number_of_images)].map((_, i) => { return (
								<div
									key={i}
									className='w-full h-full object-cover'
									style={{
										'background-image': `url('images/agriculture/${obj.image_folder}/${i + 1}.jpg')`,
										'animation-delay': `${i * 5}s`,
										'animation-duration': `${5 * obj.number_of_images}s`,
									}} />
							)})
						:
							<img src={`images/agriculture/${obj.image_folder}/1.jpg`} alt='農業の画像' className='w-full h-full object-cover' />
						}
					</div>
					<p className='mt-[40px] sm:mt-[80px] whitespace-pre-line leading-loose'>{obj.content}</p>
				</div>
			)})}
			{ products && products.length ?
				<div className='w-[90%] sm:w-4/5 mx-auto pb-[40px] sm:pb-[80px]'>
					<ProductsIndex products={products} base64Images={base64Images} />
				</div>
			: ''}
			<div className='w-[90%] sm:w-4/5 mx-auto pt-[40px] pb-[80px] sm:py-[80px]'>
				<ArticleBanners articleList={['ubai', 'narabeni', 'temple']} />
			</div>
		</div>
	);
};

export default AgriculturePage;