import { React } from 'react';
import { Title } from '../components';
import { lectureContentsList, mediaContentsList, mediaPictureList } from '../data/achievements';

const Achievements = () => {
	return (
		<div>
			<Title title='メディア・講演' englishTitle='Media' />
			<div className='py-[40px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5'>
				<p className='sm:text-[20px] text-gold-dark font-allura'>Media</p>
				<p className='mb-[20px] sm:mb-[40px] text-[20px] sm:text-[32px] font-shippori'>メディア掲載</p>
				<div className='flex flex-wrap gap-[10px] justify-between'>
					<div className='flex-1'>
						{ mediaContentsList.map((content, i) => { return (
							<div className='flex' key={i}>
								<p className='min-w-[100px]'>{content.date}</p>
								<p>{content.title}</p>
							</div>
						)})}
					</div>
					<div className='w-[325px] flex flex-col gap-[10px]'>
						{ mediaPictureList.map((name, i) => { return (
							<div key={i}>
								<img src={`images/achievements/media/${name}.jpg`} alt='メディア掲載実績の画像' className='w-full h-[200px] object-cover' />
								<p className='text-[12px]'>▲{name}</p>
							</div>
						)})}
					</div>
				</div>
			</div>
			<div className='py-[40px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5'>
				<p className='sm:text-[20px] text-gold-dark font-allura'>Lecture</p>
				<p className='mb-[20px] sm:mb-[40px] text-[20px] sm:text-[32px] font-shippori'>講演</p>
				<div className='flex flex-wrap gap-[10px] justify-between'>
					<div className='flex-1'>
						{ lectureContentsList.map((content, i) => { return (
							<div className='flex' key={i}>
								<p className='min-w-[140px]'>{content.date}</p>
								<p>{content.title}</p>
							</div>
						)})}
					</div>
					<div className='w-[325px] flex flex-col gap-[10px]'>
						{[...Array(6)].map((_, i) => { return (
							<img src={`images/achievements/lecture/${i + 1}.jpg`} alt='講演実績の画像' className='w-full h-[200px] object-cover' />
						)})}
					</div>
				</div>
			</div>
		</div>
	)
};

export default Achievements;