import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { ProductsIndex, Title } from '../components';
import { activitiesText } from '../data/activities';
import { getIndexBase64Images, getProducts } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const Activities = () => {
	const [products, setProducts] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const loading_context = useContext(LoadingContext);

	useEffect(() => {
		if (!localStorage.getItem('cart'))
			localStorage.setItem('cart', JSON.stringify([]));
		const getData = async () => {
			loading_context.setLoading(true);

			var productsData;
			var base64ImagesData;
			try {
				productsData = await getProducts(false, true);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'products', objects: productsData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setProducts(productsData);
			setBase64Images(base64ImagesData);
			loading_context.setLoading(false);
		}
		getData();
	}, []);

	return (
		<div>
			<Title title='体験イベント' englishTitle='Event' />
			<div className='sm:flex gap-[20px] py-[40px] sm:py-[80px] sm:pt-[80px] sm:pb-[160px] w-[90%] sm:w-4/5 mx-auto'>
				<div className='fade-images w-full sm:w-1/2 aspect-[4/3]' >
					{[...Array(10)].map((_, i) => { return (
							<div
								key={i}
								className='w-full h-full object-cover'
								style={{
									'background-image': `url('images/activities/${i + 1}.jpg')`,
									'animation-delay': `${i * 5}s`,
									'animation-duration': `${5 * 10}s`,
								}} />
						)})}
					</div>
				<p className='w-full sm:w-1/2 whitespace-pre-line leading-loose'>{activitiesText}</p>
			</div>
			<div className='py-[40px] sm:py-[80px] sm:pt-[80px] sm:pb-[160px] w-[90%] sm:w-4/5 mx-auto'>
				<ProductsIndex products={products} category='体験イベント' base64Images={base64Images} />
			</div>
		</div>
	);
};

export default Activities;
