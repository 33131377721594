import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { PopularItems, Title } from '../components';
import { getBlogs, getIndexBase64Images, imageSrc } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const Blogs = () => {
	const [blogs, setBlogs] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			context.setLoading(true);

			var blogsData;
			var base64ImagesData;
			try {
				blogsData = await getBlogs();
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (!blogsData) {
				context.setLoading(false);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'blogs', objects: blogsData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setBlogs(blogsData);
			setBase64Images(base64ImagesData);
			context.setLoading(false);
		}
		getData();
	}, []);

	return (
		<div className=''>
			<Title englishTitle='blog' title='ブログ' />
			{ blogs.length ? 
				<ul className='w-[90%] sm:w-4/5 mx-auto pt-[40px] sm:pt-[80px] grid sm:grid-cols-3 sm:gap-[20px]' >
					{ blogs.map((blog, i) => { return (
						<li key={i} className='py-[10px] sm:py-0 hover:opacity-60 border-b sm:border-none'>
							<a href={'/blogs/' + blog.id} className='flex gap-[10px] sm:gap-0 sm:flex-col'>
								<img
									src={imageSrc(base64Images[blog.base64Images_idx])}
									alt='goods'
									className='w-[80px] h-[80px] sm:w-full sm:h-[193px] object-cover rounded-[8px]' />
								<div className='w-full sm:py-[20px]'>
									<p className='mb-[5px] sm:mb-[10px] font-bold line-clamp-2 sm:line-clamp-1'>
										{blog.title}
									</p>
									<p className='font-mono text-stone-400'>{blog.created_at}</p>
								</div>
							</a>
						</li>
					)})}
				</ul>
				:
				<p className='mt-20 mb-80 text-center'>ブログ記事はありません</p>
			}
			<div className='w-[90%] sm:w-4/5 mx-auto'>
				<PopularItems />
			</div>
		</div>
	);
};

export default Blogs;
