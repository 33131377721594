export const aboutText = `\
	奈良月ヶ瀬に約七百年前に伝えられた製法を変えることなくそのままに、烏梅づくりを続ける日本唯一の烏梅製造元です。
	烏梅とは梅の実を加工したもので、約千三百年前に遣隋使、遣唐使によって薬として奈良に伝わりました。
	月ヶ瀬には約七百年前の南北朝時代に紅花染めの媒染剤として伝わりました。
	江戸時代後期から明治初期にかけては化粧用の紅の媒染剤として最盛期を迎え、400軒もの烏梅生産がありましたが化学染料の発明によって烏梅の需要は激減。
	昭和初期には製造者は数軒に、終戦後には梅古庵が唯一の製造元となり今に至っています。
	梅古庵では、30,000平米の広大な土地と緑あふれる豊かな自然の中で、梅、柿、米、野菜の栽培、食品加工を行っています。
	この地、月ヶ瀬の梅は元々は烏梅づくりの為に植えられたものでしたが、五月川の渓谷と川面に映える満開の梅の美しさは人々を魅了し、日本初の名勝地となりました。
	2月～3月末の梅まつり期間中当店では梅の香り漂う絶景の桟敷席でお食事とお花見をお楽しみいただけます。
	令和六年に奈良紅工房を新築しました。
	日本の職人技を随所に取り入れた日本建築です。
	繊細で高度な技術と日本的美しさを感じられる工房の中で烏梅を使った紅花染めを体験出来ます。
	体験を通して日本文化の素晴らしさを再発見出来るきっかけになることを願っています。
	日々変化する自然の色、月ヶ瀬渓谷に広がる早朝の雲海を見ていると、まるで梅源郷に居るような錯覚を覚えます。
	早春の光、静かな新緑、実りの秋、冬の香り。
	季節の仕事をその時に、厳しくも温かい月ヶ瀬の自然の中で暮らしています。`;
