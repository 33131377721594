import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Icon, PopularItems, Title } from '../components';
import { createCart, getIndexBase64Images, imageSrc } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const Cart = () => {
  const [sum, setSum] = useState(0);
  const [cart, setCart] = useState([]);
  const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);
	const navigate = useNavigate();

	const handleChange = (e, item_i) => {
		e.preventDefault();

		var value = Number(e.target.value);
		var cartListData = cart;
		const cartStorage = JSON.parse(localStorage.getItem('cart'));

		if (!cartStorage) {
			navigate('/cart');
			return ;
		}

		if (cart[item_i].subscription && value > 1) {
			window.alert('定期便の購入は1度につき1つまでです');
			value = 1;
		}
		if (value) {
			for (var i = 0; i < cart.length; i ++) {
				if ((cart[item_i].subscription || cart[i].subscription) && cart[i].number && cart[i].product_id !== cart[item_i].product_id) {
					window.alert('定期便の商品と他の商品を同時に購入することはできません');
					value = 0;
					break ;
				}
			}
		}

		cartListData[item_i].number = value;
		cartStorage[item_i].number = value;

		setCart([...cartListData]);
		localStorage.setItem('cart', JSON.stringify(cartStorage));
	}

	const handleTrash = (e, i) => {
		e.preventDefault();
		const cartListData = cart;
		const cartStorage = JSON.parse(localStorage.getItem('cart'));
		cartListData.splice(i, 1);
		cartStorage.splice(i, 1);
		setCart([...cartListData]);
		localStorage.setItem('cart', JSON.stringify(cartStorage));
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		for (var i = 0; i < cart.length; i ++) {
			if (cart[i].subscription && (cart[i].number > 1 || i !== 0)) {
				window.alert('定期便の購入は1度につき1つまでです。\nまた、他の商品との同時購入はできません。');
				return ;
			}
		}
		navigate('/order-form');
	};

	useEffect(() => {
		const getData = async () => {
			context.setLoading(true);

			var cartListData;
			try {
				cartListData = await createCart(setCart);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (!cartListData) {
				context.setLoading(false);
				return ;
			}

			var base64ImagesData;
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'products', objects: cartListData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setBase64Images(base64ImagesData);

			context.setLoading(false);
		}
		getData();
	}, []);

	useEffect(() => {
		var calcSum = 0;
		cart.map((item) => {
			calcSum += item.price * item.number;
		});
		setSum(calcSum);
	}, [cart]);

	return (
		<div>
			<Title title='お買い物かご' englishTitle='Cart' />
			{ cart.length ? 
				<div className='py-[40px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5 sm:flex'>
					<div className='sm:w-3/5'>
						<div className='h-0 sm:h-fit w-full mx-auto py-[10px] gap-[20px] hidden sm:flex'>
							<p className='flex-1 pl-[10px]'>商品</p>
							<p className='w-[80px] text-center'>価格</p>
							<p className='w-[80px] text-center'>数量</p>
						</div>
					{ cart.length ? cart.map((item, i) => { return (
						<div key={i} className='w-full mx-auto py-[10px] sm:flex gap-[20px] border-t border-stone-400 items-center'>
							<div className='flex-1 flex items-center gap-[10px]'>
								<img
									src={imageSrc(base64Images[item.base64Images_idx])}
									alt='商品画像'
									className='w-[100px] h-[100px] object-cover' />
								<div>
									<a
										href={'/products/' + item.product_id}
										className='hover:underline'>
										{item.name}
									</a>
									<div className='mt-[10px] flex items-center justify-between sm:hidden'>
										<p className='w-[80px] text-[20px] font-mono'>
											¥{new Intl.NumberFormat().format(item.price)}
										</p>
										<div className='w-[80px] flex gap-[10px] items-center'>
											<input
												value={item.number}
												min={0}
												max={item.stock}
												type='number'
												onChange={(e) => {handleChange(e, i)}}
												className='w-[50px] h-[40px] text-center border rounded invalid:text-amber-600 invalid:border-amber-600' />
											<button onClick={(e) => handleTrash(e, i)}>
												<Icon icon="trash" className='w-4 opacity-20 hover:opacity-40' />
											</button>
										</div>
									</div>
								</div>
							</div>
							<p className='sm:h-fit w-[80px] text-[20px] font-mono text-center hidden sm:block'>
								¥{new Intl.NumberFormat().format(item.price)}
							</p>
							<div className='sm:h-fit w-[80px] gap-[10px] items-center hidden sm:flex'>
								<input
									value={item.number}
									min={0}
									max={item.stock}
									type='number'
									onChange={(e) => {handleChange(e, i)}}
									className='w-[50px] h-[40px] text-center border rounded invalid:text-amber-600 invalid:border-amber-600' />
								<button onClick={(e) => handleTrash(e, i)}>
									<Icon icon="trash" className='w-4 opacity-20 hover:opacity-40' />
								</button>
							</div>
						</div>
					)}):''}
					</div>
					<div className='pt-[40px] sm:pl-[40px] sm:w-2/5'>
						<div className='py-[40px] border border-stone-400 rounded-[4px]'>
							<div className='mb-[10px] mx-auto w-[256px] flex justify-between'>
								<p className='font-mono text-[24px] font-bold'>小計</p>
								<p className='font-mono text-[24px] font-bold'>¥{sum}</p>
							</div>
							<p className='mx-auto w-[256px] text-center font-mono text-stone-400'>送料は購入手続き時に計算されます</p>
							<button
								onClick={handleSubmit}
								className='mt-[40px] mx-auto py-[10px] w-[256px] block text-center text-gold-light font-bold bg-gold-dark rounded-[4px] hover:bg-gold transition'>
								お支払いにすすむ
							</button>
						</div>
					</div>
				</div>
				: <p className='my-40 text-center'>現在、カートに入っている商品はありません。</p>
			}
			<div className='mx-auto w-[90%] sm:w-4/5'>
				<PopularItems />
			</div>
		</div>
	);
};

export default Cart;
