import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { ArticleBanners, ProductsIndex, Title, TopImagesCarousel } from '../../components';
import { ubaiTeaContent1, ubaiTeaContent2, ubaiText1, ubaiText2 } from '../../data/ubai';
import { getIndexBase64Images, getProducts } from '../../functions';
import { LoadingContext } from '../../functions/context/LoadingFunc';

const UbaiPage = () => {
	const [products, setProducts] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const loading_context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			loading_context.setLoading(true);

			var productsData;
			var base64ImagesData;
			try {
				productsData = await getProducts(false, true);
				productsData = productsData.filter((p) => p.category === '烏梅');
			} catch (err) {
				showBoundary(err);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'products', objects: productsData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setProducts(productsData);
			setBase64Images(base64ImagesData);

			loading_context.setLoading(false);
		};
		getData();
	}, []);

	return (
		<div>
			<Title title='烏梅' englishTitle='Ubai' />
			<TopImagesCarousel image_folder='images/ubai' number_of_images={14} name='ubai' />
			<div className='w-[90%] sm:w-4/5 mx-auto py-[40px] sm:py-[100px]'>
				<div className='sm:w-1/2 sm:p-[5px] float-right'>
					<img src='images/ubai/50年前の烏梅製造1.jpg' alt='50年前の烏梅製造' />
					<p className='text-[12px]'>▲50年前の烏梅製造</p>
				</div>
				<p className='leading-loose whitespace-pre-line'>
					{ubaiText1}
				</p>
			</div>
			<div className='py-[40px] sm:py-[80px] flex justify-center gap-[40px] overflow-scroll'>
				<img src='images/ubai/5.jpg' alt='烏梅製造風景' className='w-[200px] h-[200px] object-cover rounded-full border-[5px] border-gold-dark'/>
				<img src='images/ubai/6.jpg' alt='烏梅製造風景' className='w-[250px] h-[250px] object-cover rounded-full border-[5px] border-gold'/>
				<img src='images/ubai/7.jpg' alt='烏梅製造風景' className='w-[150px] h-[150px] object-cover rounded-full border-[5px] border-gold-dark'/>
				<img src='images/ubai/8.jpg' alt='烏梅製造風景' className='w-[200px] h-[200px] object-cover rounded-full border-[5px] border-gold'/>
			</div>
			<div className='sm:w-4/5 mx-auto'>
				<div className='mx-auto w-[90%] sm:w-fit py-[80px] sm:px-[20px]'>
					<p className='mb-[40px] text-[20px] sm:text-[32px] text-gold-dark font-shippori text-center'>
						紅を支えた月ヶ瀬の烏梅づくり
					</p>
					<div className='sm:w-1/2 sm:p-[5px] float-right'>
						<img src='images/ubai/50年前の烏梅製造2.jpg' alt='50年前の烏梅製造' />
						<p className='text-[12px]'>▲50年前の烏梅製造</p>
					</div>
					<p className='leading-loose whitespace-pre-line'>{ubaiText2}</p>
				</div>
			</div>
			<div className='py-[40px] sm:py-[80px] flex justify-center gap-[40px] overflow-scroll'>
				<img src='images/ubai/9.jpg' alt='烏梅製造風景' className='w-[150px] h-[150px] object-cover rounded-full border-[5px] border-gold-dark'/>
				<img src='images/ubai/10.jpg' alt='烏梅製造風景' className='w-[200px] h-[200px] object-cover rounded-full border-[5px] border-gold'/>
				<img src='images/ubai/11.jpg' alt='烏梅製造風景' className='w-[250px] h-[250px] object-cover rounded-full border-[5px] border-gold-dark'/>
				<img src='images/ubai/12.jpg' alt='烏梅製造風景' className='w-[200px] h-[200px] object-cover rounded-full border-[5px] border-gold'/>
			</div>
			<img src='images/ubai/many_ubai.jpg' alt='烏梅製造風景' className='w-full h-[150px] sm:h-[300px] object-cover'/>
			<div className='mx-auto py-[40px] sm:py-[80px] w-[90%] sm:w-4/5'>
				<div className='mb-[40px] sm:mb-[80px]'>
					<p className='text-gold-dark font-allura sm:text-[20px]'>Ubai Tea</p>
					<p className='font-shippori text-[20px] sm:text-[32px]'>薬膳烏梅茶　～烏梅の飲み方～</p>
				</div>
				<div className='mb-[80px] w-full sm:flex'>
					<div className='mb-[20px] sm:mb-0 pb-[20px] sm:py-[10px] sm:pr-[10px] sm:w-1/2 flex flex-col gap-[20px] border-b-2 sm:border-b-0 sm:border-r-2 border-dotted'>
						<p className='sm:text-[20px] font-bold text-center'>煎じる場合</p>
						<p className='sm:pl-[10px] sm:h-[160px] leading-loose whitespace-pre-line'>{ubaiTeaContent1}</p>
						<iframe
							src="https://www.youtube.com/embed/"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerpolicy="strict-origin-when-cross-origin"
							allowfullscreen
							className='mx-auto sm:w-full sm:max-w-[480px] h-[180px] sm:h-[270px] rounded-[8px]'/>
					</div>
					<div className='sm:ml-[10px] sm:py-[10px] sm:w-1/2 flex flex-col gap-[20px]'>
						<p className='sm:text-[20px] font-bold text-center'>水出し</p>
						<p className='sm:pl-[10px] sm:h-[160px] leading-loose whitespace-pre-line'>
							{ubaiTeaContent2}
						</p>
						<iframe
							src="https://www.youtube.com/embed/"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerpolicy="strict-origin-when-cross-origin"
							allowfullscreen
							className='mx-auto sm:w-full sm:max-w-[480px] h-[180px] sm:h-[270px] rounded-[8px]'/>
					</div>
				</div>
				{ products && products.length ?
					<ProductsIndex products={products} category='烏梅' base64Images={base64Images} />
				: ''}
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto pt-[40px] pb-[80px] sm:py-[80px]'>
				<ArticleBanners articleList={['narabeni', 'agriculture', 'temple']} />
			</div>
		</div>
	);
};

export default UbaiPage;