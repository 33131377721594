export const directStoreText = `\
	1万三千本の梅の花が咲く毎年２上旬～３月末（月ヶ瀬梅まつりの頃）、期間中無休で営業しております。 
	お食事処にて､天然山菜を使った鍋料理・うどん、自家栽培米を使ったごはんもの等、
	手づくり料理を提供しております。 お土産処にて梅食品､烏梅､盆梅を販売しております。
	選定保存技術『烏梅製造』の保持者が皆様のお越しをお待ちしております。
	`;

export const directStoreContents = [
	{
		title: '店舗名',
		content: '梅古庵',
	},
	{
		title: '住所',
		content: `\
			〒630-2302
			奈良県奈良市月ヶ瀬尾山2263（本社）

			〒630-2302
			奈良県奈良市月ヶ瀬尾山2265-2（店舗）`,
	},
	{
		title: '営業期間',
		content: `\
			本社　不定休

			お食事処
			1万三千本本の梅の花が咲く梅まつりの頃
			毎年2月上旬～3月末まで
			期間中無休​​`,
	},
	{
		title: '営業時間',
		content: '9:00～17:00​​',
	},
];

export const wholeSaleStoreContents = [
	{
		name: '奈良のうまいものプラザ（奈良）',
		address: '〒630-8122 奈良県奈良市三条本町１−１　ＪＲ奈良駅１Ｆ',
	},
	{
		name: 'まほろばキッチンＪＲ奈良駅前店（奈良）',
		address: '奈良県奈良市三条本町1098　ＪＲ奈良駅西口',
	},
	{
		name: '奈良蔦屋書店（奈良）',
		address: '奈良県奈良市三条大路1丁目691-1　奈良県コンベンションセンター横',
	},
	{
		name: 'くるみの木（奈良）',
		address: '奈良県奈良市法蓮町567-1',
	},
	{
		name: '鹿の舟（奈良）',
		address: '奈良県奈良市井上町11',
	},
	{
		name: 'グランドフードホール芦屋店（兵庫）',
		address: '兵庫県芦屋市東山町6-6',
	},
	{
		name: 'グランドフードホール六本木店（東京）',
		address: '東京都港区六本木6-10-1　六本木ヒルズ　ヒルサイドＢ２Ｆ',
	},
	{
		name: 'カフェ サマナラガーデン（奈良）',
		address: '〒632-0047 奈良県天理市乙木町６５１',
	},
	{
		name: '平城宮いざない舘　奈良のトビラ（奈良）',
		address: '',
	},
	{
		name: 'ホテル尾花（奈良）',
		address: '',
	},
	{
		name: 'Oyatsu & herbs（三重）',
		address: '',
	},
	{
		name: 'tabel（神奈川）',
		address: '',
	},
	{
		name: '近鉄百貨店奈良店（奈良）',
		address: '',
	},
];

export const restaurantContents = [
	{
		name: '京静華（京都）',
		address: '京都府京都市左京区岡崎円勝寺町36-3　2階',
	},
	{
		name: '発酵薬膳ラファエロ（奈良）',
		address: '〒630-8301 奈良県奈良市高畑町１０５５−１',
	},
	{
		name: 'ふ定食屋（東京）',
		address: '〒150-0013 東京都渋谷区恵比寿４丁目５番２３号',
	},
	{
		name: 'お料理やちる（東京）',
		address: '〒143-0023 東京都大田区山王２丁目７−６ メゾン安田 1F',
	},
	{
		name: '奈良ホテル（奈良）',
		address: '〒630-8301 奈良県奈良市高畑町１０９６',
	},
	{
		name: '星のや東京（東京）',
		address: '〒100-0004 東京都千代田区大手町１丁目９−１',
	},
	{
		name: 'シェ・シュシュ（愛知）',
		address: '〒485-0029 愛知県小牧市中央４丁目１６１ サンヴィレッジＳＴ２',
	},
	{
		name: '季節の手仕事カフェume（奈良）',
		address: '〒631-0033 奈良県奈良市あやめ池南６丁目１−７',
	},
	{
		name: 'ノボテル奈良（奈良）',
		address: '〒630-8115 奈良県奈良市大宮町７丁目１−４５',
	},
	{
		name: 'リストランテ・ボルゴ・コニシ（奈良）',
		address: '〒630-8226 奈良県奈良市小西町24−番地 フラッツ小西 2F',
	},
	{
		name: '枸杞（奈良）',
		address: '',
	},
];

export const eventNameText = `\
	青山ファーマーズマーケット（国連大学前）
	グリグリマルシェ（難波神社）三輪はじまりマーケット（大神神社参詣道）
	1000マーケット（清水寺）
	シェフェスタ（奈良公園）
	天平祭（平城宮跡）
	ヒルズマルシェ（赤坂）
	近鉄百貨店催事（奈良）
	東急百貨店催事（東京）`;
