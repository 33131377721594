import { React } from 'react';
import { Title } from '../components';
import { directStoreContents, directStoreText, eventNameText, restaurantContents, wholeSaleStoreContents } from '../data/shops.js';

const Shops = () => {
	return (
		<div>
			<Title title='お取扱店舗' englishTitle='Shops' />
			{/* 直営店 */}
			<div className='py-[40px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5'>
				<p className='mb-[20px] sm:mb-[60px] text-[20px] sm:text-[32px] font-shippori text-center'>
					直営店
				</p>
				<div className='fade-images mx-auto w-full sm:w-[800px] h-[200px] sm:h-[450px]'>
				{ [...Array(9)].map((_, i) => { return (
					<div
						key={i}
						className='w-full h-full object-cover'
						style={{
							'background-image': `url('images/shops/direct_store/${i + 1}.jpg')`,
							'animation-delay': `${i * 5}s`,
							'animation-duration': `${9 * 5}s`,
						}}/>
				)})}
				</div>
				<p className='sm:w-[800px] mx-auto my-[20px] sm:my-[60px] leading-loose whitespace-pre-line'>
					{directStoreText}
				</p>
				<div className='flex flex-wrap gap-[10px] sm:gap-[20px]'>
					<iframe
						title='access'
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13119.556766708443!2d136.0400668!3d34.7079747!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60014e370ca05555%3A0xd10d4ddca6559d8e!2z5qKF5Y-k5bq1!5e0!3m2!1sja!2sjp!4v1733807310730!5m2!1sja!2sjp"
						className='sm:w-[60%] h-[520px] max-w-full aspect-video bg-stone-400 rounded-[12px]'
						allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
					<div className='order-first sm:order-last sm:h-[520px] flex-1'>
						{ directStoreContents.map((obj, i) => { return (
							<div key={i} className='py-[10px] flex flex-col gap-[5px] border-b border-stone-400'>
								<p className='font-bold'>{obj.title}</p>
								<p className='whitespace-pre-line'>{obj.content}</p>
							</div>
						)})}
					</div>
				</div>
			</div>

			{/* お取扱店舗（一部） */}
			<div className='pt-[40px] pb-[80px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5'>
				<p className='sm:text-[20px] text-gold-dark font-allura'>WholeSale stores</p>
				<p className='mb-[20px] sm:mb-[40px] text-[20px] sm:text-[32px] font-shippori'>お取扱店舗（一部）</p>
				<div className='w-full'>
					{ wholeSaleStoreContents.map((store, i) => { return (
						<p className='py-[5px]' key={i}>{store.name}</p>
					)})}
				</div>
			</div>

			{/* 飲食店（一部） */}
			<div className='mx-auto w-[90%] sm:w-4/5'>
				<p className='sm:text-[20px] text-gold-dark font-allura'>Restaurants</p>
				<p className='mb-[20px] sm:mb-[40px] text-[20px] sm:text-[32px] font-shippori'>飲食店（一部）</p>
				<div className='w-full'>
					{ restaurantContents.map((store, i) => { return (
						<p className='py-[5px]' key={i}>{store.name}</p>
					)})}
				</div>
			</div>

			{/* イベント出店（一部）*/}
			<div className='py-[80px] mx-auto w-[90%] sm:w-4/5'>
				<p className='sm:text-[20px] text-gold-dark font-allura'>Event Stalls</p>
				<p className='mb-[20px] sm:mb-[40px] text-[20px] sm:text-[32px] font-shippori'>イベント出店（一部）</p>
				<p className='leading-loose whitespace-pre-line'>
					{eventNameText}
				</p>
			</div>
		</div>
	);
};

export default Shops;