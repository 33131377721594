import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { prefectureList } from '../data/main';
import { createOrders, getProduct } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const ActivityForm = () => {
	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const productId = query.get('id');
	const item = {
		product_id: productId,
		number: Number(query.get('number')),
		name: '',
		price: 0,
	};
	const [product, setProduct] = useState({});
	const [customer, setCustomer] = useState({
		email: '',
		phone: '',
		name: '',
		zipCode: '',
		prefecture: '',
		address: '',
		memo: ''
	});
	const loading_context = useContext(LoadingContext);
	const { showBoundary } = useErrorBoundary();

	const handleChange = (e) => {
		e.preventDefault();
		setCustomer({...customer, [e.target.name]: e.target.value});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		item.name = product.name;
		item.price = product.price;
		loading_context.setLoading(true);
		var orderData;
		try {
			orderData = await createOrders({cart: [item], customer});
		} catch (err) {
			showBoundary(err);
		}
		window.location.href = `/order-completed?order_id=${orderData.orderId}`;
		loading_context.setLoading(false);
	};

	useEffect(() => {
		const getData = async () => {
			loading_context.setLoading(true);

			var productData;
			try {
				productData = await getProduct(productId, true);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (productData) {
				setProduct(productData);
			} else {
				window.alert('体験イベントが存在しません');
				loading_context.setLoading(false);
				window.location.href = '/activities';
				return ;
			}
			loading_context.setLoading(false);
		};
		getData();
	}, []);

	return (
		<div className='mt-[60px] sm:mt-[80px] mx-auto py-[40px] sm:py-[80px] w-[90%] sm:w-4/5 flex flex-wrap gap-[40px] sm:gap-[10px]'>
			<form onSubmit={handleSubmit} className='w-full sm:order-first flex-1 sm:pr-[10px]'>
				<p className='font-allura text-gold-dark sm:text-[20px]'>Form</p>
				<p className='mb-[40px] font-shippori text-[20px] sm:text-[32px]'>体験イベントお申し込み</p>
				<p>体験イベント名：{product.name}</p>
				<p className='mb-[40px]'>数量：{item.number}</p>
				<div className='mb-[40px] sm:mb-[20px]'>
					<label>メールアドレス<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='email' type='email'
						className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
						value={customer.email} required />
				</div>
				<div className='mb-[20px]'>
					<label>氏名<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='name' type='text'
						className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
						value={customer.name} required />
				</div>
				<div className='mb-[20px] flex flex-col sm:flex-row gap-[20px] sm:gap-2'>
					<div className='flex-1'>
						<label>郵便番号（半角数字7桁）<span className='ml-[5px] text-amber-600'>*</span></label>
						<input
							onChange={handleChange} name='zipCode' type='text' pattern="\d{7}"
							className='w-full mt-[5px] py-[10px] px-[16px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
							value={customer.zipCode} required />
					</div>
					<div className='flex-1'>
						<label>都道府県<span className='ml-[5px] text-amber-600'>*</span></label>
						<select
							onChange={handleChange} name='prefecture'
							className='w-full mt-[5px] h-[50px] border border-stone-200 rounded invalid:border-amber-600'
							value={customer.prefecture} required>
							<option value="">選択してください</option>
							{prefectureList.map((p, i) => {
								return <option key={i} value={p}>{p}</option>
							})}
						</select>
					</div>
				</div>
				<div className='mb-[20px]'>
					<label>ご住所<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='address' type='text'
						className='w-full mt-[5px] h-[50px] py-[10px] px-[16px] border border-stone-200 rounded invalid:border-amber-600'
						value={customer.address} required />
				</div>
				<div className='mb-[40px] sm:mb-[20px]'>
					<label>電話番号（半角数字11桁）<span className='ml-[5px] text-amber-600'>*</span></label>
					<input
						onChange={handleChange} name='phone' type='tel' pattern="\d{11}"
						className='w-full mt-[5px] h-[50px] py-[10px] px-[16px] border border-stone-200 rounded invalid:border-amber-600'
						value={customer.phone} required />
				</div>
				<div className='mb-[20px]'>
					<p className='my-[20px] text-[20px] sm:text-[24px]'>備考欄</p>
					<textarea
						onChange={handleChange} name='memo'
						className='p-[10px] w-full h-[160px] border border-stone-200 rounded' />
				</div>
				<div className='flex items-center'>
					<button
						type='submit'
						className='w-full sm:w-[400px] mx-auto my-[40px] sm:mt-[20px] sm:mb-[80px] p-[10px] block text-center text-gold-light rounded-[8px] bg-stone-800 hover:bg-gold'>
						送信
					</button>
				</div>
			</form>
		</div>
	);
};

export default ActivityForm;