import { React } from 'react';
import { Title } from '../components';
import { aboutContentsList, presidentMessage } from '../data/about';

const About = () => {
	return (
		<div>
			<Title englishTitle='About' title='会社概要' />
			<div className='w-[90%] sm:w-4/5 mx-auto py-[40px] sm:py-[80px]'>
				<p className='font-allura text-gold-dark text-[20px] sm:text-[32px]'>President</p>
				<div className='h-fit flex flex-col sm:flex-row gap-[20px]'>
					<img alt='代表者の写真' src='images/about/1.jpg' className='w-full sm:min-w-[150px] sm:flex-1 object-cover' />
					<div className='flex flex-col gap-[10px]'>
						<p className='mt-[10px] sm:mt-0 text-[20px]'>梅古庵 BAIKOAN　代表</p>
						<p className='text-[20px]'>中西　謙介</p>
						<p className='h-0 sm:h-fit whitespace-pre-line leading-loose hidden sm:block'>
							{presidentMessage}
						</p>
					</div>
				</div>
				<p className='mt-[20px] sm:mt-0 h-fit whitespace-pre-line leading-loose sm:hidden'>
					{presidentMessage}
				</p>
			</div>
			<div className='w-[90%] sm:w-4/5 mx-auto pb-[40px] sm:pb-[80px]'>
				<div className='p-[20px] bg-white bg-opacity-60'>
					{ aboutContentsList.map((obj, i) => { return (
						<div className='sm:pt-[30px] pb-[10px] flex flex-wrap sm:gap-[40px]' key={i}>
							<p className='w-full sm:w-[30%] pb-[10px] sm:border-b font-bold sm:font-normal'>{obj.title}</p>
							<p className='w-full sm:flex-1 pb-[10px] border-b whitespace-pre-line'>{obj.content}</p>
						</div>
					)})}
				</div>
				<iframe
					title='access'
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.886245678384!2d136.0402645!3d34.708049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60014e37128013d9%3A0x6a1893b48333f383!2z44CSNjMwLTIzMDIg5aWI6Imv55yM5aWI6Imv5biC5pyI44O254Cs5bC-5bGx77yS77yS77yW77yT!5e0!3m2!1sja!2sjp!4v1733808763997!5m2!1sja!2sjp"
					className='mt-[40px] h-[475px] w-full aspect-video bg-stone-400 rounded-[12px]'
					allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
			</div>
		</div>
	);
};

export default About;