import { React } from 'react';
import Icon from './Icon';

const PhoneCartIcon = () => {
	return (
		<div className='fixed bottom-0 p-[20px] w-full flex justify-end sm:hidden'>
			<div className='w-[60px] h-[60px] flex items-center justify-center rounded-full bg-stone-800 hover:bg-gold-dark transition'>
				<a href='/cart'>
					<Icon icon='cart-shopping' className='w-[30px] fill-gold-light' />
				</a>
			</div>
		</div>
	);
};

export default PhoneCartIcon;