import { React, useContext, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopularItems, Title } from '../components';
import { getOrder } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const OrderCompleted = () => {
	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const orderId = query.get('order_id');
	const navigate = useNavigate();
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	useEffect(() => {
		const orderCheck = async () => {
			context.setLoading(true);
			var res;
			try {
				res = await getOrder(orderId);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (res.status === 'pending-payment')
				navigate('/');
			context.setLoading(false);
		}
		orderCheck();
	}, []);

	return (
		<div>
			<Title title='注文完了' englishTitle='Order Completed' />
			<div className='w-[90%] sm:w-4/5 mx-auto'>
				<p className='my-20 text-center text-xl'>
					ご注文番号：{orderId}
				</p>
				<p className='mb-2 text-center'>ご注文ありがとうございました。</p>
				<p className='mb-2 text-center'>注文番号は大切に保管してください。</p>
				<p className='text-center mt-16 mb-32'>
					<a href='/' className='underline hover:text-gold-dark'>トップページへ戻る</a>
				</p>
				<PopularItems />
			</div>
		</div>
	);
};

export default OrderCompleted;
