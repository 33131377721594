import { React, useEffect, useState } from 'react';
import { pagesList } from '../data/main';
import Icon from './Icon';

const Header = () => {
	const [topHeader, setTopHeader] = useState(true);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const uri = new URL(window.location.href);
		if (uri.pathname !== '/')
			setTopHeader(false);
	}, []);

	const handleMenu = (e) => {
		e.preventDefault();
		if (open)
			setOpen(false);
		else
			setOpen(true);
	};

	const MenuList = ({className}) => {
		return pagesList.map((p, i) => {
			return (
				<a href={p.pathname} key={i} className={className}>
					{p.title}
				</a>
			);
		});
	};

	const PhoneHeader = () => {
		return (
			<div className='bg-white bg-opacity-80 shadow-md sm:hidden'>
				<div className="flex items-center justify-between">
					<a href='/'>
						<img src="/logo2.jpg" alt="ロゴ" />
					</a>
					<button
						type="button"
						onClick={handleMenu}
						className="w-[60px] h-[60px] p-4 text-gold-dark"
						aria-controls="mobile-menu"
						aria-expanded="false"
					>
					{ open ? 
						<svg viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					:
						<svg viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
							<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
						</svg>
					}
					</button>
				</div>
				{ open ? 
					<div className="px-4 pb-4 space-y-1">
						<MenuList className={'text-right hover:text-gold-dark rounded-md py-2 block' } />
					</div>
				:''}
			</div>
		);
	};

	return (
		<div className='fixed z-40 top-0 left-0 right-0'>
			<PhoneHeader />
			{topHeader ? 
			<nav className='justify-end sm:pt-[40px] sm:pr-[40px] items-center hidden sm:flex'>
				<a href='https://www.instagram.com/baikoan' className='p-[10px] hover:scale-110 transition'>
					<Icon icon='instagram' className='w-[32px] h-[32px] fill-gold-dark' />
				</a>
				<a href='https://www.facebook.com/u.baikoan/' className='p-[10px] hover:scale-110 transition'>
					<Icon icon='facebook' className='w-[32px] h-[32px] fill-gold-dark' />
				</a>
				<a href='https://www.youtube.com/@baikoanchannel9723' className='p-[10px] mr-6 hover:scale-110 transition'>
					<Icon icon='youtube' className='w-[32px] h-[32px] fill-gold-dark' />
				</a>
				<a href='/cart' className='p-[15px] bg-gold-dark rounded-full hover:bg-opacity-80 hover:scale-110 transition'>
					<Icon icon='cart-shopping' className='w-[28px] h-[28px] fill-gold-light' />
				</a>
			</nav>
			:
			<nav className='px-[30px] h-[80px] items-center justify-between bg-white bg-opacity-80 shadow-md hidden sm:flex'>
				<a href='/'>
					<img className="sm:w-[168px]" src="/logo2.jpg" alt="ロゴ" />
				</a>
				<div className="hidden sm:block">
					<div className="flex items-center">
						<MenuList className={'p-[5px] hover:text-gold-dark border-b-gold-dark hover:border-b transition'} />
						<a href='/cart' className='p-[15px] ml-[10px] bg-stone-800 rounded-full hover:bg-gold-dark transition'>
							<Icon icon='cart-shopping' className='w-[28px] h-[28px] fill-gold-light' />
						</a>
					</div>
				</div>
			</nav>
			}
		</div>
	);
};

export default Header;
