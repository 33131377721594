import axios from "axios";

const createOrders = async ({cart, customer}) => {
	return await axios.post('/backend/orders', {cart, customer})
		.then((res) => {
			localStorage.setItem('cart', JSON.stringify([]));
			window.location.replace(`/order-completed?order_id=${res.data.orderId}`);
		});
};

export default createOrders;