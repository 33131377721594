import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import { Articles, Title } from '../components';
import {
	getArticle,
	getBase64Image,
	getProduct,
	imageSrc
} from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const Article = () => {
	const params = useParams();
	const articleId = Number(params.article_id);
	const [article, setArticle] = useState({});
	const [base64Image, setBase64Image] = useState('');
	const [relatedProduct, setRelatedProduct] = useState({});
	const [relatedProductBase64Image, setRelatedProductBase64Image] = useState('');
	const { showBoundary } = useErrorBoundary();
	const loading_context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			loading_context.setLoading(true);

			var articleData;
			try {
				articleData = await getArticle(articleId);
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (articleData) {
				var base64ImageData;
				try {
					base64ImageData = await getBase64Image({ table: 'articles', image_id: articleId });
				} catch (err) {
					showBoundary(err);
					return ;
				}
				setArticle(articleData);
				setBase64Image(base64ImageData);
				if (articleData.product_id) {
					var relatedProductData;
					try {
						relatedProductData = await getProduct(articleData.product_id, true);
					} catch (err) {
						showBoundary(err);
						return ;
					}
					if (relatedProductData) {
						var relatedProductBase64ImageData;
						try {
							relatedProductBase64ImageData = await getBase64Image({ table: 'products', image_id: relatedProductData.image_id });
						} catch (err) {
							showBoundary(err);
							return ;
						}
						setRelatedProduct(relatedProductData);
						setRelatedProductBase64Image(relatedProductBase64ImageData);
					}
				}
			} else {
				window.alert('商品が存在しません');
				loading_context.setLoading(false);
				window.location.href = '/articles';
				return ;
			}

			loading_context.setLoading(false);
		}
		getData();
	}, []);

	return (
		<div>
			<Title title={article.title} />
			<div className='pt-[40px] sm:mt-[80px] sm:mb-[40px] mx-auto w-[90%] sm:w-4/5'>
				<img
					src={imageSrc(base64Image)}
					alt='特集記事トップ画像'
					className='sm:w-[640px] sm:h-[400px] mx-auto sm:mb-16 aspect-[3/2] object-contain bg-stone-200' />
				<p className='mt-[40px] sm:mt-[80px] whitespace-pre-line leading-loose'>{article.content}</p>
				{ article.link && article.link_title ? 
					<p className='mt-[40px] text-center'>
						<a
							href={article.link}
							target="_blank"
							rel="noopener noreferrer"
							className='underline hover:text-gold-dark'>
							{article.link_title}
						</a>
					</p>
				: ''}
				{ relatedProduct.id ? 
					<div className='mt-[80px] mx-auto w-full sm:w-[325px] hover:opacity-60 transition'>
						<a href={'/products/' + relatedProduct.id}>
							<img
								src={imageSrc(relatedProductBase64Image)}
								alt='goods'
								className='mb-[10px] mx-auto w-full sm:w-[325px] aspect-square sm:aspect-video object-cover rounded-[12px]' />
							<div className='sm:w-[325px] mx-auto'>
								<p className='sm:mb-[10px] py-2 line-clamp-2'>
									{relatedProduct.name}
								</p>
								<p className='text-[20px] font-mono font-bold text-center'>
									¥{new Intl.NumberFormat().format(relatedProduct.price)}
								</p>
								{relatedProduct.stock ? '' : 
									<p className='text-sm text-center text-amber-600 font-mono'>在庫なし</p>
								}
							</div>
						</a>
					</div>
				: ''}
			</div>
			<div className='mx-auto py-[80px] w-[90%] sm:w-4/5'>
				<Articles />
			</div>
		</div>
	);
};

export default Article;
