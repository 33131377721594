import { React } from 'react';

const TopImagesCarousel = ({image_folder, number_of_images, name}) => {	
	return (
		<div className='relative h-[150px] sm:h-[300px] overflow-hidden'>
			<div className='flex h-full'>
				<img
					src={`${image_folder}/1.jpg`}
					alt='トップ画像'
					className='w-1/3 h-full object-cover' />
				<img
					src={`${image_folder}/2.jpg`}
					alt='トップ画像'
					className='w-1/3 h-full object-cover' />
				<img
					src={`${image_folder}/3.jpg`}
					alt='トップ画像'
					className='w-1/3 h-full object-cover' />
			</div>
			<div className={`absolute top-0 right-0 top-image-slide-${name} flex h-full`}>
			{ [...Array(number_of_images + 4)].map((_, i) => { return (
				<img
					src={`${image_folder}/${i % number_of_images + 1}.jpg`}
					alt='トップ画像'
					className='w-1/2 sm:w-1/3 h-full object-cover bg-stone-400' />
			)})}
			</div>
		</div>
	);
};

export default TopImagesCarousel;