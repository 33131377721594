export const presidentMessage = `\
	烏梅づくりを営む中西家に産まれ、幼少の頃から烏梅づくりの手伝いを始める。
	大学卒業後に自動車メーカーにて研究開発の仕事を15年続けたのち帰郷。
	日本に昔からある良い物、それを『伝統』と呼ばれないように、現代に生かすには？
	変えてはいけないところ、変えるべきところを模索し、挑戦を続けています。
	烏梅職人十代目、奈良紅工房主宰、園生の森神社管理人
	2023年 COREZO賞受賞`;

export const aboutContentsList = [
	{
		title: "会社名",
		content: "梅古庵",
	},
	{
		title: "営業開始",
		content: "明治時代",
	},
	{
		title: "代表者",
		content: "中西謙介",
	},
	{
		title: "所在地",
		content: `\
			本社　〒630-2302　奈良県奈良市月ヶ瀬尾山2263
			店舗　〒630-2302　奈良県奈良市月ヶ瀬尾山2265-2
			園生の森神社（奈良紅工房）〒630-2302　奈良県奈良市月ヶ瀬尾山1133`,
	},
	{
		title: "電話",
		content: "0743-92-0560",
	},
	{
		title: "メール",
		content: "baikoan@outlook.jp",
	},
	{
		title: "事業内容",
		content: "烏梅製造、食品製造加工販売、化粧品製造、お食事処、紅花染め体験教室、農業",
	},
];
