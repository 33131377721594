const Title = ({title, englishTitle}) => {
	return (
		<div className='pt-[100px] sm:pt-[160px] pb-[40px] sm:pb-[80px] text-center bg-stone-800'>
			<p className="font-allura sm:text-[20px] text-gold-dark">
				{englishTitle}
			</p>
			<p className='font-shippori text-[24px] sm:text-[36px] text-gold-light'>
				{title}
			</p>
		</div>
	);
};

export default Title;