export const ubaiText1 = `\
	烏梅（ウバイ）：千三百年の歴史と守り続ける技術
	
	1. 烏梅の伝来
	烏梅とは梅の実を加工したもので、約千三百年前に随から日本に伝わった歴史的な薬草です。
	遣隋使によって奈良に薬として持ち込まれ、日本の薬草文化の一つとして受け入れられました。
	烏梅は奈良時代から長い間、日本の医療や日常生活の中で重要な役割を果たしてきました。
	
	2. 月ヶ瀬での伝来と変遷
	月ヶ瀬には、烏梅が紅花染めの媒染剤として約七百年前に伝わりました。京都の染色職人たちは、
	この黒い梅の実を媒染剤として利用し、その特性を活かして紅色鮮やかな糸や生地を染めました。
	江戸時代から明治にかけては烏梅の需要がさらに広がり、口紅の原料としても利用されました。
	最盛期には月ヶ瀬には約400軒の烏梅製造所があり、地域の重要な産業となっていました。
	
	3. 需要の変化と化学染料の影響
	しかし、明治時代に化学染料の発明と普及が進み、天然染料である烏梅の需要は激減しました。
	化学染料の便利さと価格の低さが、昔ながらの染料の市場を圧迫したのです。
	その結果、多くの製造所が閉鎖され烏梅製造は衰退していきました。
	
	4.現代の唯一の製造所：月ヶ瀬の中西家『梅古庵』
	そのような状況の中で、月ヶ瀬の中西家は烏梅の製造を継続しました。中西家では、
	代々口伝によって烏梅の製法を継承し、歴史的な技術を守り続けています。
	戦後の食糧難の時代にもかかわらず烏梅の製造を続けた中西家は、その貴重な技術と知識を
	未来に伝えるための努力を惜しみませんでした。
	
	5. 現代における烏梅の利用と価値
	現代では、月ヶ瀬の中西家が営む『梅古庵』が唯一、歴史的な製法と原材料を守り、烏梅を
	作り続けています。烏梅は現代でも十二単の染色や東大寺の修二会の花こしらえにおいて
	重要な材料として利用されています。これらのことは烏梅の文化的価値を象徴しています。
	また、薬膳料理としてその深い風味と効果が重宝されています。
	特別な贈り物や珍しい食材としても人気です。
	
	まとめ
	烏梅は千三百年の歴史を持ち、薬として日本に伝わり、月ヶ瀬では媒染剤や口紅の原料として
	製造されてきました。明治以降、化学染料の普及により需要が激減し多くの製造所が
	閉鎖される中で、中西家『梅古庵』は代々口伝によってその製法を継承し、唯一の製造所として
	文化を守り続けています。現代においても、十二単や東大寺の修二会、薬膳料理などで
	利用され続ける烏梅の魅力は、長い歴史と文化によって支えられています。
	その独自の価値と歴史的背景が、烏梅を特別な存在として今日も輝かせています。
	歴史と技術が織り成すこの貴重な素材をぜひ多くの人々にご利用いただければと思います。`;

export const ubaiText2 = `\
	奈良県の北東に位置する月ヶ瀬梅林は江戸時代の儒学者頼山陽が『和州（大和）の
	香世界を観るに非ずんば、人生何ぞ梅花を説くべけんや』と称賛し、多くの文人墨客が
	訪れるようになったという梅の名所。幕末以降明治大正と多くの観光客が来村し、
	毎年春には道列をなした。満開の梅の香りと五月川の深い渓谷を見おろす絶景は今日なお
	人々を魅了し、毎年20万人近い観光客を迎える。大正11年には国の名勝第１号に指定され、
	令和4年には名勝指定100周年となる。月ヶ瀬梅林の歴史は古く、元久2(1205)年に真福寺境内に
	あった天神社に祭神菅原道真公をお祀りしたときに梅の木を植えたのが始まりという
	言い伝えがある。この梅林、実は観賞用として植えられたものではない。烏梅を作るために
	植えられたものである。

	烏梅とは完熟した梅を燻蒸して乾燥させたもの。正徳3（1713）年刊行の百科事典
	『和漢三才図会』では烏梅に「布須倍牟女」（ふすべうめ）と説明し、製造法を記載している。
	烏梅は遣隋使が薬として日本に伝えたのが最初とされ、万葉集にも登場する。
	『延喜式』の中には烏梅丸の名が見え、妙薬として用いられた。また、烏梅は紅花染めや
	お化粧用の紅をつくる時の媒染剤としても利用された。烏梅の水溶液が紅を定着させ、
	色鮮やかに発色させる役割を果たす。 

	月ヶ瀬梅林に烏梅が伝えられたのは南北朝時代のこと。元弘元年(1331)、後醍醐天皇が
	敗戦落城のおり、近侍の女官達がこの地に逃れた。そのうちの一人、園生姫が月ヶ瀬の梅を見て、
	助けてもらったお礼に烏梅の製法を伝えた。村人達は教えられた通りに烏梅を京の都に送ると
	大変高値で米より収入が良かった。人々は競って梅を植え烏梅づくりに精を出したという。

	紅の輝きを支え、貴重な収入源として村の経済を支えた烏梅も、明治以降に西洋から安価で
	手間の少ない化学染料が輸入されると需要は激減した。烏梅を生産する農家も次々と養蚕や
	製茶業へ転身せざるを得なく、戦後には１軒のみとなってしまった。 戦中、戦後の食糧難の厳しい
	時代には、これを作っていても良いのだろうかと自問自答し、葛藤しながらも守られた烏梅。
	昔ながらのやり方を守ること、伝統製法にはきっと意味があり、そこに価値があるのだと思う。
	「生涯ここで暮らすなら烏梅を忘れるな。売れても売れなくても梅を焼け。天神様が守ってくれる。
	先祖からの言い伝えだ。」という教えを大切に。これからも。`;

export const ubaiTeaContent1 = `\
	１粒に対して300ccの水を入れて鍋で煎じてください。
	沸騰してから5分が目安です。酸味のお好みで煮出し時間を調節してください。
	２～３煎可能です。`;

export const ubaiTeaContent2 = `\
	１粒に対して1ℓの水を入れて、冷蔵庫で24時間浸してください。`;
