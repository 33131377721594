import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { ArticleBanners, ProductsIndex, Title, TopImagesCarousel } from '../../components';
import { narabeniContentsList } from '../../data/narabeni';
import { getIndexBase64Images, getProducts } from '../../functions';
import { LoadingContext } from '../../functions/context/LoadingFunc';

const NarabeniPage = () => {
	const [products, setProducts] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const loading_context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			loading_context.setLoading(true);

			var productsData;
			var base64ImagesData;
			try {
				productsData = await getProducts(false, true);
				productsData = productsData.filter((p) => p.category === '奈良紅');
			} catch (err) {
				showBoundary(err);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'products', objects: productsData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setProducts(productsData);
			setBase64Images(base64ImagesData);

			loading_context.setLoading(false);
		};
		getData();
	}, []);

	return (
		<div>
			<Title title='奈良紅' englishTitle='Nara beni' />
			<TopImagesCarousel image_folder='images/narabeni/other' number_of_images={3} name='narabeni' />
			{ narabeniContentsList.map((obj) => { return (
				<div className='py-[40px] sm:py-[80px] mx-auto w-[90%] sm:w-4/5'>
					<p className='sm:text-[20px] font-allura text-gold-dark'>{obj.english_title}</p>
					<p className='mb-[40px] text-[20px] sm:text-[32px] font-shippori'>{obj.title}</p>
					<div className={`${obj.number_of_images > 1 ? 'fade-images w-full sm:w-1/2 aspect-[4/3] float-left' : 'w-full sm:w-1/3 float-right'} sm:mx-[10px]`}>
						{ obj.number_of_images > 1 ? 
							[...Array(obj.number_of_images)].map((_, i) => { return (
								<div
									key={i}
									className='w-full h-full'
									style={{
										'background-image': `url('images/narabeni/${obj.image_folder}/${i + 1}.jpg')`,
										'animation-delay': `${i * 5}s`,
										'animation-duration': `${5 * obj.number_of_images}s`,
									}} />
							)})
						:
							<div>
								<img
									src={`images/narabeni/${obj.image_folder}/1.jpg`}
									alt='奈良紅の画像'/>
								<p className='text-[12px]'>{`\
									▲当世美人合踊師匠
									画：国立国会図書館デジタルコレクションより
								`}</p>
							</div>
						}
					</div>
					<p className='whitespace-pre-line leading-loose'>{obj.content}</p>
				</div>
			)})}
			{ products && products.length ?
				<div className='w-[90%] sm:w-4/5 mx-auto pb-[40px] sm:pb-[80px]'>
					<ProductsIndex products={products} category='奈良紅' base64Images={base64Images} />
				</div>
			: ''}
			<div className='w-[90%] sm:w-4/5 mx-auto pt-[40px] pb-[80px] sm:py-[80px]'>
				<ArticleBanners articleList={['ubai', 'agriculture', 'temple']} />
			</div>
		</div>
	)
};

export default NarabeniPage;