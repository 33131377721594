import { React } from 'react';
import { pagesList } from '../data/main';
import Icon from './Icon';

const Footer = () => {
	const MenuList = () => {
		return (
			<div className='grid sm:grid-cols-3 xl:grid-cols-4'>
			<a href='/' className='p-[5px] w-[150px] border-b border-b-gold-dark hover:border-b-gold-light'>
				トップページ
			</a>
			{ pagesList.map((p, i) => {
				return (
					<a href={p.pathname} key={i} className='p-[5px] w-[150px] border-b border-b-gold-dark hover:border-b-gold-light'>
						{p.title}
					</a>
				);
			})}
			<a href='/achievements' className='p-[5px] w-[150px] border-b border-b-gold-dark hover:border-b-gold-light'>
				メディア・講演
			</a>
			<a href='/about' className='p-[5px] w-[150px] border-b border-b-gold-dark hover:border-b-gold-light'>
				会社概要・アクセス
			</a>
			<a href='mailto:' className='p-[5px] w-[150px] border-b border-b-gold-dark hover:border-b-gold-light'>
				お問い合わせ
			</a>
			</div>
		);
	};

	return (
		<div className='pt-[60px] pb-[30px] bg-gold-dark text-gold-light'>
			<div className='xl:max-w-[1280px] mx-auto px-[30px] sm:flex'>
				<div className='flex-1'>
					<p className='mb-[10px] text-[20px] sm:text-[28px] font-bold'>烏梅製造元　梅古庵</p>
					<p className='mb-[10px]'>〒630-2302 奈良県奈良市月ヶ瀬尾山 2263</p>
					<div className='flex flex-wrap sm:gap-[40px] items-center'>
						<p>E-mail: baikoan@outlook.jp</p>
						<div className='flex'>
							<a href='https://www.instagram.com/baikoan' target='blank' rel="noopener noreferrer" className='p-[10px] hover:scale-110 transition'>
								<Icon icon='instagram' className='w-[32px] h-[32px] fill-gold-light'/>
							</a>
							<a href='https://www.facebook.com/u.baikoan/' target='blank' rel="noopener noreferrer" className='p-[10px] hover:scale-110 transition'>
								<Icon icon='facebook' className='w-[32px] h-[32px] fill-gold-light'/>
							</a>
							<a href='https://www.youtube.com/@baikoanchannel9723' target='blank' rel="noopener noreferrer" className='p-[10px] hover:scale-110 transition'>
								<Icon icon='youtube' className='w-[32px] h-[32px] fill-gold-light'/>
							</a>
						</div>
					</div>
				</div>
				<div className='flex-1'>
					<MenuList/>
					<a href='/cart' className='w-[150px] mt-[10px] p-[10px] flex gap-[10px] border block group hover:bg-gold-light hover:text-gold-dark'>
						<Icon icon='cart-shopping' className='w-[16px] fill-gold-light group-hover:fill-gold-dark' />
						お買い物かご
					</a>
				</div>
			</div>
			<p className='mt-[40px] text-[13px] text-center'>&copy; 2024 梅古庵</p>
			<p className='text-[13px] text-center'>
				<a
					href='https://chisso06.notion.site/chisso06/Chisso-s-Portfolio-868e0394f3ab4748a01bd5929fa42e0b#310bdc25a6c544bfbc5a679c94a3c70f'
					className='border-b'
					target='blank'
					rel="noopener noreferrer">
					created by chisso
				</a>
			</p>
		</div>
	);
};

export default Footer;