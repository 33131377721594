import { React, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { getArticles, getIndexBase64Images, imageSrc } from '../functions';
import { LoadingContext } from '../functions/context/LoadingFunc';

const Articles = () => {
	const [articles, setArticles] = useState([]);
	const [base64Images, setBase64Images] = useState([]);
	const { showBoundary } = useErrorBoundary();
	const context = useContext(LoadingContext);

	useEffect(() => {
		const getData = async () => {
			context.setLoading(true);

			var articlesData;
			var base64ImagesData;
			try {
				articlesData = await getArticles();
			} catch (err) {
				showBoundary(err);
				return ;
			}
			if (!articlesData) {
				context.setLoading(false);
				return ;
			}
			try {
				base64ImagesData = await getIndexBase64Images({ table: 'articles', objects: articlesData });
			} catch (err) {
				showBoundary(err);
				return ;
			}
			setArticles(articlesData);
			setBase64Images(base64ImagesData);
			context.setLoading(false);
		}
		getData();
	}, []);

	return (
		articles.length ? 
			<ul className='grid grid-cols-2 sm:grid-cols-3 gap-x-[10px]'>
				{ articles.length ? articles.map((article, i) => {
					return (
						<li key={i}>
							<a
								href={'/articles/' + article.id}
								className='block' >
								<img
									src={imageSrc(base64Images[article.base64Images_idx])}
									alt='goods'
									className='w-full h-[120px] sm:h-[200px] object-cover hover:bg-opacity-50' />
								<p className='mx-auto mt-2 mb-8 py-1 sm:w-40 block text-sm text-center text-gold-dark border border-gold-dark hover:text-gold-light hover:bg-gold-dark hover:scale-110 transition line-clamp-1'>
									{article.title} <span className='text-xs'>▶︎</span>
								</p>
							</a>
						</li>
					);
				}):''}
			</ul>
		:''
	);
};

export default Articles;
